/* eslint-disable react/jsx-no-target-blank */
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AsideMenuItem } from './AsideMenuItem'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  //
  const params = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const path = useLocation().pathname.split('/')[1]

  // Icon will be navigate to alert list if no permission
  // const { currentUser, logout } = useAuth()
  // const statisticPermission = currentUser?.permissions?.find((permissions) => permissions?.service === 'STATISTICS');
  // const haveDashBoardPermission = statisticPermission?.access?.some((access) => access === 'dashboard.read') ?? false;

  return (
    <>
      <div className='px-2 pt-5'>
        {/* {haveDashBoardPermission && <AsideMenuItem
          to='/dashboard'
          title='Dashboard'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/graphs/gra007.svg'
        />} */}

        <div className='menu-item'>
          <div className='menu-content pt-5 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
          </div>
        </div>

        <AsideMenuItemWithSub
          to='/ethoca'
          title='Ethoca Alert'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen007.svg'
        >
          <AsideMenuItemWithSub to='/ethoca/alerts' title='Alerts' hasBullet={true}>
            <AsideMenuItem
              to='/ethoca/alerts/list'
              title='List'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/ethoca/alerts/upload-status/list'
              title='Upload Status'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          <AsideMenuItem to='/ethoca/descriptors/list' title='Descriptors' hasBullet={true} />

          {/* <AsideMenuItemWithSub to='/ethoca/clients' title='Clients' hasBullet={true}> */}
          <AsideMenuItem to='/ethoca/clients/list' title='Clients' hasBullet={true} />
          {/* <AsideMenuItem to='/ethoca/clients/tree' title='Tree' hasBullet={true} /> */}
          {/* </AsideMenuItemWithSub> */}

          {/* <AsideMenuItem to='/ethoca/users/list' title='Users' hasBullet={true} /> */}
          <AsideMenuItem to='/ethoca/enquiries/list' title='Enquiries' hasBullet={true} />
          {/* <AsideMenuItem to='/ethoca/audit-logs/list' title='Audit Logs' hasBullet={true} /> */}
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/rdr'
          title='Verifi RDR'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/abstract/abs023.svg'
        >
          <AsideMenuItem to='/rdr/cases/list' title='Cases' hasBullet={true} />
          <AsideMenuItem to='/rdr/clients/list' title='Clients' hasBullet={true} />
          <AsideMenuItem to='/rdr/caids/list' title='CAIDs' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/beceipt'
          title='ICS'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/abstract/abs014.svg'
        >
          <AsideMenuItem to='/beceipt/transactions/list' title='Transactions' hasBullet={true} />
          <AsideMenuItem to='/beceipt/requests/list' title='Buyer Requests' hasBullet={true} />
          {/* <AsideMenuItem to='/beceipt/failed-emails/list' title='Failed Emails' hasBullet={true} /> */}
          {/* <AsideMenuItem to='/beceipt/clients/list' title='Clients' hasBullet={true} /> */}
        </AsideMenuItemWithSub>

        {/* <AsideMenuItemWithSub
          to='/monitor'
          title='Monitor'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/electronics/elc001.svg'
        >
          <AsideMenuItem to='/monitor/duplicate/list' title='Duplicate Alerts' hasBullet={true} />
          <AsideMenuItem to='/monitor/duplicate-ethoca/list' title='Ethoca Duplicate Alerts' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/settlement'
          title='Settlement'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin001.svg'
        >
          <AsideMenuItem to='/settlement/duplicate-arn/list' title='ARN Duplicate Alerts' hasBullet={true} />
          <AsideMenuItem to='/settlement/recall-rdr/list' title='RDR Recalled Cases' hasBullet={true} />
          <AsideMenuItem to='/settlement/clients/list' title='Clients' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/user'
          title='Admin'
          header={true}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen049.svg'
        >
          <AsideMenuItem to='/users/list' title='Users' hasBullet={true} />
        </AsideMenuItemWithSub> */}
      </div>
    </>
  )
}
