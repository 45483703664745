import clsx from 'clsx';
import { useIntl } from 'react-intl';
import Select from 'react-select';

export type Props = {
    title: string,
    globalFilterValue: { value: string | boolean, label: string }[],
    options: { value: string | boolean, label: string }[],
    className?: string,
    hideOptions?: { value: string | boolean, label: string }[],
    onChange: (selectedOptions: { value: string | boolean, label: string }[]) => void,
}

export default function MultiSelection({ title, globalFilterValue = [], options, className, hideOptions, onChange }: Props) {
    const intl = useIntl();
    function handleChange(selectedOptions) {
        onChange(selectedOptions);
    };

    function removeHideOptions(options: { value: string | boolean, label: string }[], hideOptions: { value: string | boolean, label: string }[]) {
        return options.filter((options) => !hideOptions.some((hideOption) => hideOption.value === options.value))
    };

    return (
        <div className='me-7'>
            {/* Header */}
            <div className='menu-content'>
                <div className='menu-content py-1'>
                    <span className='menu-section text-dark text-uppercase fs-8 ls-1'>
                        {title}
                    </span>
                </div>
            </div>
            {/* Content */}
            <div className={clsx('menu-content py-0', className ? className : 'min-w-200px')}>
                <Select
                    placeholder={intl.formatMessage({ id: "COMMON.SELECT" })}
                    isMulti
                    className='border border-gray-100'
                    options={hideOptions !== undefined ? removeHideOptions(options, hideOptions) : options}
                    value={hideOptions !== undefined ? removeHideOptions(globalFilterValue, hideOptions) : globalFilterValue}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}