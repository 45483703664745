import dfFormat from 'date-fns/format'
import {formatInTimeZone} from 'date-fns-tz'

import type {HTMLAttributes, PropsWithChildren} from 'react'
import clsx from 'clsx'

export type Props = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
  date: Date
  format: string
  utc?: boolean
}

function Time({date, format, utc = false, className}: Props) {
  return (
		<span className="flex gap-x-1">
			<time className={clsx('', className)} dateTime={date.toISOString()}>
				{formatInTimeZone(date, 'Asia/Hong_Kong', format)}
			</time>
			{utc && <span className='fs-7'> (UTC+8)</span>}
		</span>
	);
}

export default Time
