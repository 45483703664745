import clsx from 'clsx';
import Time from '../time/Time';
import { PropsWithChildren } from 'react';

interface Props {
  date: Date;
  showDate?: Boolean;
  dateFormat?: string;
  badgeColor:
    | 'text-primary'
    | 'text-success'
    | 'text-info'
    | 'text-warning'
    | 'text-danger'
    | 'text-muted';
  badgeType?: 'donut';
}

export default function TimelineItem({
  date,
  showDate = true,
  dateFormat = 'HH:mm',
  badgeColor,
  badgeType = 'donut',
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className='timeline-item'>
      <div className='timeline-label fw-bolder text-gray-800'>
        {showDate ? <Time date={date} format={dateFormat} /> : '—'}
      </div>
      <div className='timeline-badge'>
        {badgeType === 'donut' && <i className={clsx('fa fa-genderless fs-1', badgeColor)} />}
      </div>
      <div className='timeline-content ps-3'>{children}</div>
    </div>
  );
}
