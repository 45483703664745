import { useState } from 'react';

type Props = {
    src: string;
    fallback?: string; // display this src where the image fails to load
    alt?: string;
    className?: string;
}

export default function ImageWithFallback({ src, fallback = "", alt, className }: Props) {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = () => {
        setImgSrc(fallback); // Set fallback image on error
    };

    return <img className={className} src={imgSrc} alt={alt} onError={handleError} />;
}