import clsx from "clsx";
import { format } from "date-fns-tz";
import user from '@/assets/user.png';
import merchant from '@/assets/merchant.png';
import tick from '@/assets/tick.png';
import doubleTick from '@/assets/double_tick.png';
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";

export type Messages = {
    type: 'DECISION_TREE' | 'MESSAGE';
    sender: MessageRole;
    step: Array<number | string> | null; // DECISION_TREE = Array , MESSAGE = null
    message: string | JSX.Element,
    timestamp: string,
    status: "READ" | "UNREAD",
}

export type MessageRole = 'BUYER' | 'MERCHANT'
interface Props {
    data?: Messages;
    direction: 'LEFT' | 'RIGHT';
    currentRole: MessageRole;
    children?: React.ReactNode | undefined;
};

export default function Message({ data, direction, currentRole, children }: Props) {
    const isRight = direction === 'RIGHT';

    return (
        <div className={clsx("d-flex align-items-end gap-3 px-4", isRight && 'justify-content-end')}>
            {/* Display icon on the left */}
            {!isRight &&
                <div className="text-center flex-shrink-0">
                    <div className="d-flex align-items-center border border-2 border-dark rounded-circle mx-auto" style={{ width: '2rem', height: '2rem' }}>
                        <img alt="Merchant Icon" className="mx-auto ms-1" style={{ width: '1.25rem', height: '1.25rem' }} src={toAbsoluteUrl('/media/icons/assets/merchant.png')} />
                    </div>
                    <div className="fs-8 fw-bold">ICS</div>
                </div>
            }

            {/* Message bubble */}
            <div className="d-flex flex-column gap-2">
                <div
                    className={clsx(
                        "d-flex flex-column border border-secondary rounded-3 p-2",
                        isRight ? 'bg-danger bg-opacity-10 rounded-end-0' : 'bg-white rounded-start-0'
                    )}
                >
                    <p className="small m-0 text-break text-muted">
                        {children ? children : data?.message}
                    </p>
                </div>

                {/* Timestamp */}
                <div className={clsx("d-flex align-items-center", isRight && "justify-content-end")}>
                    <span className="small text-muted mx-1">
                        {data?.timestamp && format(new Date(data.timestamp), 'yyyy/MM/dd HH:mm')}
                    </span>
                    {/* only merchant can see status */}
                    {currentRole === 'MERCHANT' && <>
                        {isRight && data?.status === 'UNREAD' ? <img alt="tick" style={{ width: '0.5rem', height: '0.5rem' }} src={toAbsoluteUrl('/media/icons/assets/tick.png')} /> :
                            <img alt="tick" style={{ width: '0.75rem', height: '0.75rem' }} src={toAbsoluteUrl('/media/icons/assets/double_tick.png')} />}
                    </>}
                </div>
            </div>

            {/* Display icon on the right */}
            {isRight &&
                <div className="text-center flex-shrink-0">
                    <div className="d-flex align-items-center border border-2 border-dark rounded-circle mx-auto" style={{ width: '2rem', height: '2rem' }}>
                        <img alt="Merchant Icon" className="mx-auto" style={{ width: '1.25rem', height: '1.25rem' }} src={toAbsoluteUrl('/media/icons/assets/user.png')} />
                    </div>
                    <div className="fs-8 fw-bold">Buyer</div>
                </div>
            }
        </div>
    );
}