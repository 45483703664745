import clsx from "clsx";
import { ReactNode } from "react";

type Props = {
    title?: string,
    showNoDataText?: boolean,
    breakPage?: boolean,
    backgroundImg?: boolean,
    children?: ReactNode,
    style?: React.CSSProperties;
};

export default function PrintCard({ title, showNoDataText = true, breakPage = false, backgroundImg = false, children, style }: Props) {
    return (
        <div className={clsx('card mb-5 border print-card print-margin', breakPage && 'print-page-break', backgroundImg && 'bgi-no-repeat position-center bgi-size-cover')} style={style}>
            {title && <div className='card-header'>
                <div className='card-title m-0'>
                    <h1 className='fw-bolder m-0'>{title}</h1>
                </div>
            </div>}
            <div className='card-body p-9'>
                {children ? children : <div className="text-center fs-6 text-muted">{showNoDataText && <>No Corresponding Data</>}</div>}
            </div>

            <div className='text-dark order-2 order-md-1 text-center'>
                <span className='text-muted fw-bold me-2'>&copy;</span>
                <span className='text-muted text-hover-primary fw-bold'>
                    Austreme. All Rights Reserved.
                </span>
            </div>
        </div>
    )
}