import { creditCardType } from "../../../../../../../_metronic/helpers/AssetHelpers";

export enum RefundStatus {
    FULLREFUND = 'FULL_REFUND',
    PARTIALREFUND = 'PARTIAL_REFUND',
    EXCHANGE = 'EXCHANGE',
    NOTREFUND = 'NOT_REFUND',
    OTHERS = 'OTHERS',
};

export function refundTemplate(data) {
    const imgPath = process.env.REACT_APP_ICS_BUYER_IMG_BASE_URL
    const ICONS = {
        refund: `${imgPath}icons/refund.png`,
        refundDark: `${imgPath}icons/refundDark.png`,
        bot: `${imgPath}icons/botGray.png`,
        botDark: `${imgPath}icons/botDark.png`,
        logo: `${imgPath}logo-gray.png`,
        logoDark: `${imgPath}logo-dark.png`,
    }
    const FONT_FAMILY =
        'font-family: "Open Sans", "Helvetica Neue", Helvetica, system-ui;';
    const TXN_DETAILS_TD_STYLE =
        'border-bottom-width: 1px; border-bottom-color: #ddd; border-bottom-style: solid; font-size: 15px; line-height: 24px; margin: 0; padding: 10px;';
    const BORDER_STYLE =
        'border-width: 0.5px; border-style: solid; border-color: #e1a6b0;';

    let emailTitle = '';
    let emailSubtitle = '';
    let titlePNG = ICONS.refund;
    let titlePNGDark = ICONS.refundDark;
    let cardPNG = '';
    let refundDetailHeader = ''
    let refundDetailContent = ''

    let cc = creditCardType(
        `${data.transaction.card_prefix}000000${data.transaction.card_suffix}`
    );
    if (cc === 'VISA') {
        cardPNG = `${imgPath}card/visa.png`;
    } else if (cc === 'MASTERCARD') {
        cardPNG = `${imgPath}card/mastercard.png`;
    } else {
        cc = '';
    }

    if (data.refund.status === RefundStatus.NOTREFUND) {
        emailTitle = 'Refund Unsuccessful';
        emailSubtitle = 'We regret to inform you that your request for a refund was unsuccessful.';
        refundDetailHeader = 'Reject Reason: ';
        refundDetailContent = data.refund.info.reason;
    } else {  // full refund or partial refund
        emailTitle = 'Refund Submitted';
        emailSubtitle = 'Please allow at least one week for the refund to be credited to your account.';
        refundDetailHeader = 'Refunded Amount: ';
        refundDetailContent = `${data.refund.currency} ${Number(data.refund.status === 'PARTIAL_REFUND'
            ? data.refund.partial_refund.at(-1)
            : data.refund.amount
        ).toFixed(2)}`;
    }

    return `
<!DOCTYPE html>
<html lang='en' xmlns:v='urn:schemas-microsoft-com:vml'>

<head>
    <meta charset='UTF-8'>
    <meta name='x-apple-disable-message-reformatting'>
    <meta name='viewport' content='width=device-width, initial-scale=1'>
    <meta name='format-detection' content='telephone=no, date=no, address=no, email=no, url=no'>
    <meta name='color-scheme' content='light dark'>
    <meta name='supported-color-schemes' content='light dark'>
    <!--[if mso]>
        <noscript>
            <xml>
                <o:OfficeDocumentSettings xmlns:o='urn:schemas-microsoft-com:office:office'>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
        </noscript>
        <style>
            td, th, div, p, a, h1, h2, h3, h4, h5, h6 {
                ${FONT_FAMILY}
                mso-line-height-rule: exactly;
            }
        </style>
    <![endif]-->
    <style>
        @media (prefers-color-scheme: dark) {
            .ogsb-inline {
                display: inline !important
            }
            .ogsb-hidden {
                display: none !important
            }
        }
        [data-ogsb] .ogsb-inline {
            display: inline !important
        }
    
        [data-ogsb] .ogsb-hidden {
            display: none !important
        }
    </style>
</head>

<body>
    <table class='body' valign='top' role='presentation' border='0' cellpadding='0' cellspacing='0'
    style='outline: 0; width: 98%; min-width: 98%; height: 100%; margin: 0; padding: 0; border-width: 0;'>
        <tbody>
            <tr>
                <td valign='top' style='line-height: 24px; font-size: 16px; margin: 0;' align='left'>
                    <div style='background-color: #f0e5de; padding: 25px;'>
                        <div
                            style='width: 100%; max-width: 550px; background-color: #ffffff; border-radius: 15px; overflow: hidden; margin: 0 auto; ${BORDER_STYLE}'>
                            <div style='background-color: #eb9f9f; border-radius: 15px 15px 0 0; padding: 40px 0px 30px;'>
                                <div
                                    style='font-family: "Times New Roman"; font-style: italic; font-size: 32px; font-weight: 600; margin-bottom: 25px;'
                                    align='center'>
                                    ${emailTitle}
                                </div>
                                <div align='center'>
                                    <img class='ogsb-hidden'
                                        src='${titlePNG}'
                                        alt='${emailTitle}'
                                        style='height: 65px; width: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0;'>
                                    <img class='ogsb-inline'
                                        src='${titlePNGDark}'
                                        alt='${emailTitle} dark'
                                        style='height: 65px; width: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0; display: none;'>
                                </div>
                                <div align='center' 
                                    style='font-size: 15px; ${FONT_FAMILY}; line-height: 24px; font-style: italic; margin: 0; padding: 15px 20px 0; '>
                                    ${emailSubtitle}
                                </div>
                            </div>

                            <div style='background-color: #f0e5de; padding: 15px 30px;'>
                                <table style='width: 100%; border-collapse: collapse; ${FONT_FAMILY}' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='padding-right: 15px; height: 50px; width: 55px;'>
                                            <img class='ogsb-hidden'
                                                src='${ICONS.bot}'
                                                alt='bot'
                                                style='vertical-align: middle; height: 50px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0;'>
                                            <img class='ogsb-inline'
                                                src='${ICONS.botDark}'
                                                alt='bot dark'
                                                style='vertical-align: middle; height: 50px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0; display: none;'>
                                        </td>
                                        <td style='line-height: 24px;'>
                                            <p style='color: #828282; font-size: 12px; line-height: 16px; text-align: center; margin: 0;'>
                                                You received this email because of your recent purchase. 
                                                <span style='font-weight: bold; color: #000;'>
                                                    Beceipt is an automated customer service robot subscribed by the seller
                                                </span>
                                                to assist you with your online purchase.
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div style='padding: 30px; ${FONT_FAMILY} '>
                                <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Transaction Amount:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction.currency} ${Number(data.transaction.amount).toFixed(2).toString()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            ${refundDetailHeader}
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${refundDetailContent}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Order Date:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction.transaction_date_time}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Merchant&nbsp;Name&nbsp;On Statement:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction.merchant_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Order ID:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction.order_reference}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Paid By:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            <p style='line-height: 24px; width: 100%; margin: 0; padding: 0px;'>
                                                <span>
                                                    **** **** **** ${data.transaction.card_suffix}
                                                </span>
                                                <img
                                                    style='vertical-align: middle; height: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0;'
                                                    src='${cardPNG}' alt='${cc.toLowerCase()}' width='26'>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div style='padding-bottom: 15px; ${FONT_FAMILY}'>
                                <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='line-height: 24px; font-size: 16px; margin: 0;' align='center'>
                                            <p style='line-height: 24px; font-size: 16px; width: 100%; margin: 0;' align='center'>
                                                <span style='font-size: 15px; color: #777;'>Powered by</span>
                                                <img src='${ICONS.logo}' 
                                                    class='ogsb-hidden' alt='logo'
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; border: 0; height: 24px; width: auto;'>
                                                <img src='${ICONS.logoDark}' 
                                                    class='ogsb-inline' alt='logo dark'
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; border: 0; display: none; height: 24px; width: auto;'>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>
`;
}