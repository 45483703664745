import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { api } from '../../../../../modules/api/api';
import { useToast } from '../../../../../modules/components/toast/ToastContext';
import Message from './Message';

type Props = {
	icsId: string;
}

export default function BuyerMessage({ icsId }: Props) {
	const intl = useIntl();
	const { setToast } = useToast();

	// Fetch Data
	const { data, status, refetch } = useQuery(['listMessage', icsId], async () => {
		try {
			const { result, status } = await api(
				`list_message/${icsId}`,
				{ method: 'GET', },
				{ token: undefined, refresh: undefined },
				'ics-message',
				'MERCHANT'
			).then((resp) => {
				return { result: resp?.json(), status: resp.status };
			});
			if (status === 200) return result;
		} catch (err) {
			const response = await err?.response?.json();
			setToast({
				show: true,
				type: 'Danger',
				header: intl.formatMessage({ id: 'MESSAGE.ERROR' }),
				message: response?.message ?? err.message,
			});
		}
	});

	return (
		<div className="d-flex flex-column h-100 p-2">
			{/* Chat messages container */}
			{data?.results && <div className="flex-grow-1 overflow-auto">
				{data?.results?.messagebox?.messages?.length > 0 ?
					<div className="d-flex flex-column gap-3 py-3">
						{data?.results?.messagebox?.messages?.map((message, index) => (
							<Message key={index} data={message} direction={message.sender === "BUYER" ? "RIGHT" : "LEFT"} currentRole={'BUYER'} />
						))}
					</div> :
					<div className='text-center text-muted fs-4 fst-italic'>No Messages</div>}
			</div>}
		</div>
	)
}