import { format } from "date-fns-tz";
import labelValue from "../../../../../modules/components/labelValue/LabelValue"

type Props = {
    data: {
        delivered_date: string
        delivery_date: string,
        delivery_status: string,
        delivery_info: string,
        site_domain: string,
    };
    tracking_url: string,
}

export default function ShippingInfo({ data, tracking_url }: Props) {
    const formattedDeliveredDate = data.delivered_date !== "" ? format(new Date(data.delivered_date), 'yyyy-MM-dd') : "";
    const formattedDeliveryDate = data.delivery_date !== "" ? format(new Date(data.delivery_date), 'yyyy-MM-dd') : "";

    return (
        <div className="card">
            <div className='card shadow-none'>
                <div className='card-header'>
                    <div className='card-title m-0 flex flex-column'>
                        <h3 className='fw-bolder m-0'>{data.site_domain}</h3>
                        <div className="fs-8 text-muted">{tracking_url}</div>
                    </div>
                </div>
                <div className='card-body p-9'>
                    {labelValue('Shipped On', formattedDeliveryDate)}
                    {labelValue('Delivery Status', `${data.delivery_status} ${formattedDeliveredDate}`)}
                    {labelValue('Delivery Info', data.delivery_info)}
                </div>
            </div>
        </div>
    )
}